import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
  filter,
  interval,
  lastValueFrom,
  Observable,
  Subject,
  Subscription,
  takeUntil
} from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { NetworkService } from '@core/services/network.service';
import { ResellerService } from '@core/services/reseller.service';
import { TitleService } from '@core/services/title.service';
import { environment } from '@environment';
import { PublicService } from '@features/services/public.service';
import { APP_LANGUAGES, DEFAULT_LANGUAGE, LOCAL_STORAGE_KEYS } from '@shared/utils/constants';
import { formatDigits, generateGUID } from '@shared/utils/functions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  showLayout = false;
  isOnline: boolean = true;

  countdown = '00:00:00';
  currentLanguage!: string;
  viewAs?: string;
  private end = 0;
  private timer: Observable<number> = interval(1000);
  private timerSub?: Subscription;
  private sessionIdSub?: Subscription;
  private userSub?: Subscription;
  private ticketSub?: Subscription;
  private destroy$ = new Subject<void>(); // Used for auto-unsubscribing

  constructor(
    private router: Router,
    private translate: TranslateService,
    private resellerService: ResellerService,
    private titleService: TitleService,
    private publicService: PublicService,
    private authService: AuthService,
    private networkService: NetworkService
  ) {
    if (environment.production) {
      console.log = function () {};
      console.error = function () {};
      console.info = function () {};
      console.debug = function () {};
    }
  }

  private getData(route: ActivatedRouteSnapshot, key: string) {
    let data = route.data[key];
    if (route.firstChild) data = this.getData(route.firstChild, key) || data;
    return data;
  }

  private initRouting() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const currentRoute = this.router.routerState.snapshot.root;
      this.showLayout = this.getData(currentRoute, 'showLayout');
      this.titleService.setTitle(this.getData(currentRoute, 'title'));
    });
  }

  private initTranslate() {
    this.translate.addLangs(APP_LANGUAGES.map(l => l.key));
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
    this.translate.use(DEFAULT_LANGUAGE);
  }

  private async initReseller() {
    const { data } = await lastValueFrom(this.publicService.getByHostname());
    this.resellerService.init(data);
  }

  async ngOnInit() {
    this.initRouting();
    this.initTranslate();

    this.networkService.isOnline.subscribe(status => {
      this.isOnline = status;
    });

    if (
      !location.host.includes('dev') &&
      !location.host.includes('localhost') &&
      !location.host.includes('stag') &&
      !location.toString().includes('presentation')
    ) {
      this.userSub = this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe(async data => {
        //  this.authService.user$.subscribe(async data => {
        if (data) {
          if (!localStorage.getItem(LOCAL_STORAGE_KEYS.CLIENT_SESSION_ID)) {
            const clientSessionId = generateGUID();
            localStorage.setItem(LOCAL_STORAGE_KEYS.CLIENT_SESSION_ID, clientSessionId);
            await this.authService.updateClientSessionId(
              this.authService.user!._id,
              clientSessionId
            );
          }

          this.sessionIdSub?.unsubscribe();

          this.sessionIdSub = this.authService
            .listenClientSessionIdUpdates(data._id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (data: any) => {
                if (data) {
                  const localClientSessionId = localStorage.getItem(
                    LOCAL_STORAGE_KEYS.CLIENT_SESSION_ID
                  );

                  if (data.clientSessionId !== localClientSessionId) {
                    this.authService.signOut();
                    // localStorage.setItem(LOCAL_STORAGE_KEYS.SESSION_EXPIRED, 'session-expired');
                    localStorage.removeItem(LOCAL_STORAGE_KEYS.SESSION_EXPIRED);
                    location.assign('/sign-in?');
                  }
                }
              }
            });
        }
      });
    }

    await this.initReseller();

    //  this.authService._ticket$.subscribe(ticket => {
    this.ticketSub = this.authService._ticket$.pipe(takeUntil(this.destroy$)).subscribe(ticket => {
      this.viewAs = ticket ? ticket.email : undefined;
      this.end = ticket ? ticket.end : 0;

      if (this.viewAs) {
        /*
          this.timerSub = timer(0, 1000) // Create a new timer on page load
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.updateCountdown();
          });*/
        this.timerSub = this.timer.subscribe(x => {
          this.updateCountdown();
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.sessionIdSub?.unsubscribe();
    this.userSub?.unsubscribe();
    this.ticketSub?.unsubscribe();
  }

  private updateCountdown() {
    if (!this.viewAs) return;

    const now = Date.now();
    const remaining = this.end - now;

    if (remaining <= 0) {
      this.countdown = '00:00:00';
      window.location.reload();
    } else {
      const hours = Math.floor(remaining / (1000 * 60 * 60));
      const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
      this.countdown = `${formatDigits(hours)}:${formatDigits(minutes)}:${formatDigits(seconds)}`;
    }
  }
}
