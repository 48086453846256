import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SoundManagerService } from '@features/services/sound-manager.service';
import { SoundPath } from '@shared/utils/enums';

@Component({
  selector: 'app-play-sound',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './play-sound.component.html',
  styleUrl: './play-sound.component.css'
})
export class PlaySoundComponent implements OnInit {
  volume: number = 50;

  constructor(private soundManager: SoundManagerService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.play('wav');
    }, 2000);
  }

  play(type: string) {
    try {
      this.soundManager.playSound(
        type == 'wav' ? SoundPath.DING : SoundPath.DING_MP3,
        this.volume / 100
      );
    } catch (error: any) {
      console.error(error);
    }
  }
}
