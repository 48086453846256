@if (viewAs) {
  <div class="alert-full-width">
    {{ 'nav-bar.support.viewing-as' | translate }}: {{ viewAs }} -
    {{ 'nav-bar.support.time-remaining' | translate }}: {{ countdown }}
  </div>
}

@if (showLayout) {
  <div id="components-room-list">
    <div class="row-room-list">
      <app-nav-bar></app-nav-bar>
      <router-outlet></router-outlet>
    </div>
  </div>
} @else {
  <router-outlet></router-outlet>
}

@if (!isOnline) {
  <app-loading-modal
    [title]="'common.network.lost-connection-title' | translate"
    [message]="'common.network.lost-connection-action' | translate"
    [loading]="false"
  ></app-loading-modal>
}
