import { Component, inject } from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

import { ThemeParameters } from '@core/interfaces/theme-parameters';
import { AuthService } from '@core/services/auth.service';
import { ThemeService } from '@core/services/theme.service';
import { DEFAULT_APPLICATION_LOGO_PATH, LOCAL_STORAGE_KEYS } from '@shared/utils/constants';

@Component({
  selector: 'app-attendee-kicked',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './attendee-kicked.component.html',
  styleUrl: './attendee-kicked.component.css'
})
export class AttendeeKickedComponent {
  authService = inject(AuthService);
  translateService = inject(TranslateService);
  themeService = inject(ThemeService);

  title: string = '';
  message: string = '';
  logo: string = DEFAULT_APPLICATION_LOGO_PATH;

  async ngOnInit() {
    let messageTitleKey: string | null = localStorage.getItem('message-title');
    let messageContentKey: string | null = localStorage.getItem('message-key');
    let logo = localStorage.getItem(LOCAL_STORAGE_KEYS.ORGANIZATION_LOGO);
    let primaryColor = localStorage.getItem(LOCAL_STORAGE_KEYS.ORGANIZATION_PRIMARY_COLOR);
    let secondaryColor = localStorage.getItem(LOCAL_STORAGE_KEYS.ORGANIZATION_SECONDARY_COLOR);

    if (logo) {
      this.logo = logo;
    }

    if (primaryColor && secondaryColor) {
      const themeParameters: ThemeParameters = {
        primaryColor,
        secondaryColor
      };

      this.themeService.setParameters(themeParameters);
    }

    this.title = await firstValueFrom(
      this.translateService.get(messageTitleKey || 'kicked-user.title')
    );

    this.message = await firstValueFrom(
      this.translateService.get(messageContentKey || 'kicked-user.message')
    );

    localStorage.removeItem('message-title');
    localStorage.removeItem('message-key');

    this.authService.user$.subscribe(async user => {
      if (user && this.authService.firebaseUser?.isAnonymous) {
        await this.authService.signOut();
      }
    });
  }

  redirectToHome() {
    location.assign('/');
  }
}
