<!-- Base module-->
<div class="row-head">
   <div class="column-sitetitle">
      <a [routerLink]="['/home/rooms']"><img *ngIf="companyLogoUrl.length > 0" [src]="companyLogoUrl"
            alt="Linkroom" /></a>
   </div>
   <div class="row-button">
      <div class="column-list-menu">
         <a [routerLink]="['/home/rooms']" routerLinkActive="active">Rooms</a>
         <a href="javascript:void(0)" *ngIf="hasOrganizations" (click)="galleryRedirect()">Gallery</a>
      </div>
      <div id="column-menu-user" class="column-menu" [style.display]="showAccountOptions ? '' : 'none'">
         <div class="row-datauser">
            <div class="avatar-datauser" style="background:#2351E5">
               <span>{{ getInitials(userName) }}</span>
               <!-- <img src="/assets/images/thumbnails-1.jpg"/> -->
            </div>
            <div class="datauser">
               <h3>{{ userName }}</h3>
               <small *ngIf="hasLicense" class="licensed-datauser" (click)="showProfilePermissions(true)">Verified
                  License</small>
            </div>
         </div>
         <div class="row-listmenu-username">
            <a href="javascript:void(0)" (click)="promptNewName()" class="icon-username"><i></i> My Name</a>
            <a *ngIf="hasOrganizations" href="javascript:void(0)" (click)="promptOrganizationSelect()"
               class="icon-organization"><i></i> Organization</a>
            <a *ngIf="!isSaUser && hasOrganizations" href="javascript:void(0)" (click)="goToAdmin()"
               class="icon-admin"><i></i> Admin</a>
            <a *ngIf="!isSaUser && profileLoaded && hasOrganizations" (click)="showSupportRequests(true)"
               class="icon-support"><i></i> Support Requests</a>
            <a *ngIf="hasOrganizations" (click)="showPresentations(true)" class="icon-presentations"><i></i>
               Presentations</a>
            <a (click)="showSelectLanguage(true)" class="icon-language"><i></i>
               Language</a>
            <a href="javascript:void(0)" (click)="logout()" class="icon-logout"><i></i> Log Out</a>
         </div>
      </div>
      <div class="column-username toggleUserOptions" id="column-username">
         <a href="javascript:void(0)" class="toggleUserOptions">
            <span class="username" (click)="toggleAccountOptions()">{{ userName }}</span>
            <div class="avatar-username " style="background:#2351E5" (click)="toggleAccountOptions()">
               <span class="toggleUserOptions">{{ getInitials(userName) }}</span>
               <i class="licensed-username" *ngIf="hasLicense"></i>
               <!-- <img src="/assets/images/thumbnails-1.jpg"/> -->
            </div>
         </a>
      </div>
   </div>
</div>

<!-- Popup Field -->
<div class="modal-field" id="accountDetails" style="display: none">
   <div class="container-modal-field" *ngIf="profile && formGroupAccount">
      <div class="modal-layer-field"></div>
      <div class="modal-content-field">
         <div class="modal-header-field">
            <h2>Account</h2>
            <button type="button" class="close-modal-field" (click)="showAccountForm(false)"></button>
         </div>
         <div class="modal-body-field">
            <form [formGroup]="formGroupAccount" (ngSubmit)="saveAccountData()" autocomplete="off">
               <div class="row-field">
                  <label>First Name</label>
                  <input type="text" formControlName="firstName" #firstName
                     [ngClass]="invalidFieldAccount(firstName) ? 'requiredfield' : ''" />
                  <label class="requiredtext" *ngIf="invalidFieldAccount(firstName)">
                     {{ invalidMessageAccount(firstName) }} <br /><br />
                  </label>
               </div>
               <div class="row-field" style="margin-top: 1em">
                  <label>Last Name</label>
                  <input type="text" formControlName="lastName" #lastName
                     [ngClass]="invalidFieldAccount(lastName) ? 'requiredfield' : ''" />
                  <label class="requiredtext" *ngIf="invalidFieldAccount(lastName)">
                     {{ invalidMessageAccount(lastName) }} <br /><br />
                  </label>
               </div>
               <div class="row-button-modal-field">
                  <button type="button" [disabled]="isAccountUpdating" class="modal-button-cancel-field"
                     (click)="showAccountForm(false)">
                     Cancel
                  </button>
                  <button type="submit" [disabled]="isAccountUpdating" class="modal-button-save-field">
                     {{ isAccountUpdating ? 'Saving...' : 'Save' }}
                  </button>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<div class="modal-field" id="invitationForm" style="display: none">
   <div class="container-modal-field" *ngIf="formGroupInvitation">
      <div class="modal-layer-field"></div>
      <div class="modal-content-field">
         <div class="modal-header-field">
            <h2>Invitation</h2>
            <button type="button" class="close-modal-field" (click)="showInvitationForm(false)"></button>
         </div>
         <div class="modal-body-field">
            <form [formGroup]="formGroupInvitation" (ngSubmit)="evalInvitationCode()" autocomplete="off">
               <div class="row-field">
                  <label>Enter code</label>
                  <input type="text" formControlName="code" #code
                     [ngClass]="invalidFieldInvitation(code) ? 'requiredfield' : ''" />
                  <label class="requiredtext" *ngIf="invalidFieldInvitation(code)">
                     {{ invalidMessageInvitation(code) }} <br /><br />
                  </label>
               </div>
               <div class="row-button-modal-field">
                  <button type="button" [disabled]="isCheckingInvitation" class="modal-button-cancel-field"
                     (click)="showInvitationForm(false)">
                     Cancel
                  </button>
                  <button type="submit" [disabled]="isCheckingInvitation" class="modal-button-save-field">
                     {{ isCheckingInvitation ? 'Checking...' : 'Apply' }}
                  </button>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>


<div class="modal-field" id="languageForm" style="display: none">
   <div class="container-modal-field">
      <div class="modal-layer-field"></div>
      <div class="modal-content-field">
         <div class="modal-header-field">
            <h2>Language</h2>
            <button type="button" class="close-modal-field" (click)="showSelectLanguage(false)"></button>
         </div>
         <div class="modal-body-field">
            <div class="column-field">
               <div class="field">
                  <select #languages [(ngModel)]="selectedLanguage">
                     <option value="en">{{ 'common.english-lang' | translate }}</option>
                     <option value="es">{{ 'common.spanish-lang' | translate }}</option>
                  </select>
               </div>
            </div>
            <div class="row-button-modal-field">
               <button type="button" class="modal-button-cancel-field" (click)="showSelectLanguage(false)">
                  Cancel
               </button>
               <button type="button" class="modal-button-save-field" (click)="setLanguage(languages.value)">
                  Apply
               </button>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="modal-field" id="presentations" style="display: none">
   <div class="container-modal-field">
      <div class="modal-layer-field"></div>
      <div class="modal-content-field" style="width: 50vw">
         <div class="modal-header-field">
            <div style="display: flex; align-items: center">
               <h2>{{ 'home-nav-bar.presentations.list.title' | translate }}</h2>

               <button class="presentations-add" (click)="showPresentations(false)"
                  [routerLink]="['/home/presentations/new']">
                  {{ 'common.add' | translate }}
               </button>
            </div>

            <button class="close-modal-field" (click)="showPresentations(false)"></button>
         </div>
         <div class="modal-body-field scrolltable">
            <form autocomplete="off">
               <div class="row-field-table">
                  <table>
                     <thead>
                        <tr>
                           <th>{{ 'common.name' | translate }}</th>
                           <th style="width: 100px">
                              {{ 'home-nav-bar.presentations.list.actions' | translate }}
                           </th>
                           <th>{{ 'common.shared' | translate }}</th>
                           <th style="width: 100px"></th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let item of presentations">
                           <td>{{ item.name }}</td>
                           <td>{{ item.actions.length }}</td>
                           <td>
                              <ng-container *ngIf="item.recipients.length == 0; else displayRecipientsContainer">
                                 <span style="padding: 1em;">{{ item.recipients.length }}</span>
                              </ng-container>
                              <ng-template #displayRecipientsContainer>
                                 <a href="javascript:void(0)" (click)="displayRecipients(true, item)"
                                    style="padding: 1em;">
                                    {{ item.recipients.length }}
                                 </a>
                              </ng-template>
                           </td>
                           <td>
                              <div class="row-button-modal-field">
                                 <button class="modal-button-save-field" (click)="showPresentations(false)"
                                    [routerLink]="['/home/presentations', item._id]">
                                    {{ 'common.edit' | translate }}
                                 </button>
                                 <button class="modal-button-save-field" (click)="sharePresentations(item._id)">
                                    {{ 'common.share' | translate }}
                                 </button>
                              </div>
                           </td>
                        </tr>
                        <tr *ngIf="sharedPresentationsWithMe.length > 0">
                           <td colspan="5" style="text-align: center;">
                              <h3 style="font-weight: bold;">Shared with me</h3>
                           </td>
                        </tr>
                        <tr *ngFor="let item of sharedPresentationsWithMe; let i = index;">
                           <td>{{ item.name }}</td>
                           <td>{{ item.actions.length }}</td>
                           <td></td>
                           <td>
                              <div class="row-button-modal-field">
                                 <button class="modal-button-save-field" (click)="removeSharedPresentation(item, i)">
                                    {{ 'common.remove' | translate }}
                                 </button>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<div class="modal-field" id="presentation-recipients" style="display: none">
   <div class="container-modal-field">
      <div class="modal-layer-field"></div>
      <div class="modal-content-field" style="width: 50vw">
         <div class="modal-header-field">
            <div style="display: flex; align-items: center">
               <h2>{{ 'home-nav-bar.presentations.list.title' | translate }} {{ selectedPresentation != undefined ?
                  selectedPresentation.name : '-' }}</h2>
            </div>

            <button class="close-modal-field" (click)="displayRecipients(false)"></button>
         </div>
         <div class="modal-body-field scrolltable">
            <form autocomplete="off">
               <div class="row-field-table">
                  <table>
                     <thead>
                        <tr>
                           <th>{{ 'common.name' | translate }}</th>
                           <th style="width: 100px"></th>
                        </tr>
                     </thead>
                     <tbody *ngIf="selectedPresentation">
                        <tr *ngFor="let item of selectedPresentation.recipients">
                           <td>{{ item.name }}
                              <br />
                              <small>{{ item.email }}</small>
                           </td>
                           <td>
                              <div class="row-button-modal-field">
                                 <button class="modal-button-save-field"
                                    (click)="removeSharedPresentationRecipient(item.sharedPresentationId)">
                                    {{ 'common.remove' | translate }}
                                 </button>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<div class="modal-field" id="supportRequests" style="display: none">
   <div class="container-modal-field">
      <div class="modal-layer-field"></div>
      <div class="modal-content-field" style="width: 45vw">
         <div class="modal-header-field">
            <h2>{{ 'home-nav-bar.support.list.title' | translate }}</h2>
            <button type="button" class="close-modal-field" (click)="showSupportRequests(false)"></button>
         </div>
         <div class="modal-body-field scrolltable">
            <form autocomplete="off">
               <div class="row-field-table">
                  <table>
                     <thead>
                        <tr>
                           <th>{{ 'home-nav-bar.support.list.sent-by' | translate }}</th>
                           <th>{{ 'home-nav-bar.support.list.duration' | translate }}</th>
                           <th>{{ 'home-nav-bar.support.list.message' | translate }}</th>
                           <th></th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let request of accessRequests">
                           <td>{{ request.sender }}</td>
                           <td>{{ formatDuration(request.duration) }}</td>
                           <td>{{ request.message }}</td>
                           <td class="button">
                              <ng-container *ngIf="request.status === 'pending'; else requestStatus">
                                 <button type="button" class="btnapproved_accessRequests"
                                    (click)="updateAccessRequest(request._id, 'approved')"></button>
                                 <button type="button" class="btndenied_accessRequests"
                                    (click)="updateAccessRequest(request._id, 'denied')"></button>
                              </ng-container>
                              <ng-template #requestStatus>
                                 {{ request.status | titlecase }}
                              </ng-template>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<div class="modal-field" id="licensePermissions" style="display: none">
   <div class="container-modal-field">
      <div class="modal-layer-field"></div>
      <div class="modal-content-field" style="width: 45vw">
         <div class="modal-header-field">
            <h2>{{ 'home-nav-bar.license.title' | translate }}</h2>
            <button type="button" class="close-modal-field" (click)="showProfilePermissions(false)"></button>
         </div>
         <div class="modal-body-field scrolltable">
            <div class="row-field-table">
               <table>
                  <thead>
                     <tr>
                        <th>{{ 'home-nav-bar.license.name' | translate }}</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let permission of permissions">
                        <td>{{ permission }}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- End Base module -->