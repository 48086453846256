import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { Closable } from '@core/interfaces/closable';
import { OrganizationService } from '@features/services/organization.service';
import { fadeInOut } from '@shared/utils/animations';

@Component({
  selector: 'app-select-organization',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  templateUrl: './select-organization.component.html',
  styleUrl: './select-organization.component.css',
  animations: [fadeInOut]
})
export class SelectOrganizationComponent extends Closable implements OnInit {
  loading = false;
  currentOrganizationId: string | null = null;
  formGroup!: FormGroup;

  constructor(
    public organizationService: OrganizationService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      organization: [this.currentOrganizationId ?? '', Validators.required]
    });
  }

  closeModal() {
   this.result.next(null);
   this.close();
  }

  async select() {
    if (this.formGroup.invalid) return;

    try {
      this.loading = true;
      await this.organizationService.selectOrganization(this.formGroup.value.organization);
      this.result.next(true);
      this.close();
    } catch (error) {
      console.error(error);
    }
  }
}
