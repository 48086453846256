export const ENDPOINTS = {
   accessRequests: '/access-requests',
   actions: '/actions',
   assets: '/assets',
   auth_agora: '/auth/agora',
   auth_photon: '/auth/photon',
   auth_providers: '/public/auth/providers',
   custom_worlds: '/custom-worlds',
   invites: '/invites',
   organizations: '/organizations',
   permissions: '/profiles/permissions',
   permissions_description: '/profiles/permissions-description',
   presentations: '/presentations',
   shared_presentations: '/shared',
   profiles: '/profiles',
   rooms: '/rooms',
   resellers: 'public/resellers',
   reset_password: '/auth/reset-password',
   roles: '/roles',
   scenes: '/scenes',
   supportAccess: '/support-access',
   tags: '/tags',
   verify: '/auth/verify',
   world: '/worlds',
   pixel: '/pixel-worlds',
   qr: '/qr'
};

export const RESELLER = {
   collection: 'resellers',
   current: 'linkroomv2-admin-dev.web.app',
   endpoint: 'resellers',
   header: 'linkroom-reseller',
   linkroom: 'ad1401fcce5df1fc12be071e9efe149505323b20e8cf9493e94150096e4549ad',
   template: 'reseller'
};

export const VIP = {
   collection: 'vips',
   endpoint: 'vips',
   template: 'activate-vip',
   vipUrl: 'vip.com'
};

export const VIP_PROFILE = {
   collection: 'vip-profiles',
   endpoint: 'vip-profiles'
};

export const EVENT = {
   collection: 'events',
   endpoint: 'events'
};

export const GUEST = {
   collection: 'guests'
};

export const REQUEST_HEADERS = {
   RESELLER: 'linkroom-reseller',
   ORGANIZATION: 'linkroom-organization',
   PROFILE: 'linkroom-profile',
   CLIENT_SESSION_ID: 'Client-Session-Id'
};

export const LOCAL_STORAGE_KEYS = {
   ATTENDEE_NAME: 'attendee-name',
   PROFILE_DATA: 'profile-data',
   ACCESS_TOKEN: 'access_token',
   PROFILE_ID: 'profileId',
   DEVICE_SETTINGS_COMPLETE: 'setup-device',
   PASSWORD_CHALLENGE_COMPLETE: 'password-challenge',
   ROOM_ATTENDEE_NICKNAME: 'attendee-nickname',
   PROFILE_CURRENT_ORGANIZATION_ID: 'current-organization-id',
   PROFILE_CURRENT_ORGANIZATION_NAME: 'current-organization-name',

   SELECTED_INPUT_VIDEO_DEVICE: 'selectedInputVideoDevice',
   SELECTED_INPUT_AUDIO_DEVICE: 'selectedInputAudioDevice',
   SELECTED_OUTPUT_AUDIO_DEVICE: 'selectedOutputAudioDevice',
   JOIN_WITH_MICROPHONE: 'jowinWithMicrophone',
   JOIN_WITH_CAMERA: 'joinWithCamera',

   SELECTED_THEME: 'selected-theme',
   LIGHT_THEME_NAME: 'light-mode',
   DARK_THEME_NAME: 'dark-mode',

   GALLERY_CURRENT_VIEW: 'view',
   PERMISSIONS_LIST: 'permissions',

   NAVIGATION_TUTORIAL_REQUIRED: 'navigation-tutorial-required',
   SELECTED_LANGUAGE: 'lang',
   CLIENT_SESSION_ID: 'lsid',
   SESSION_EXPIRED: 'lsid',
   ROOM_ID_TO_REDIRECT: 'room-to-redirect',
   ORGANZATION_LOGO_URL: 'organization-logo-url'
};

export const HOVERCODE_QR = {
   WORKDSPACE_ID: '7c799528-eed2-44ef-b449-ebcb48fd4966',
   API_TOKEN: '9edf3d90e1fd7f7c7aa8fa55a4d34a47bb781b2f'
};

export const APP_VERSION = 3;
export const PAGE_LOADED = 'loaded';
export const PREFIX_BROWSER_TITLE = 'Linkroom';
export const SOCIAL_NETWORK_SIGN_IN = 'sm';
export const SIGN_IN_MODE = 'sign-in-mode';
export const JOIN_MEETING_URL_PATH = 'join-meeting';
export const DEVICE_SETTINGS_URL_PATH = 'device-settings';
export const GALLERY_PATH = 'gallery';
export const DEFAULT_ATTENDEE_LIST_TITLE = 'Attendees';
export const DEFAULT_LINKPAD_LOGO = 'assets/images/linkroom_logo-sq.png';
export const CRYPTO_SEED = '3a0da83b-5d49-4224-96f9-4d9de9cc017c';

export const INVITE_COLLECTION_NAME = 'invites';

export const SWAL_CONFIRM_BUTTON_COLOR = '#2B5597';
export const SWAL_CANCEL_BUTTON_COLOR = '#59b2cf';

export const PRIVACY_POLICY_URL = 'https://www.linkroom.com/privacy-policy';
export const TERMS_OF_USE_URL = 'https://www.linkroom.com/terms-of-use ';

export const RESELLER_ID = 'reseller-id';
export const DEFAULT_LANGUAGE = 'en';

export const DEFAULT_APPLICATION_LOGO_PATH = 'assets/images/logo.svg';
export const PUBLIC_PATH_STORAGE = 'public';
export const PUBLIC_PROFILE_PATH_STORAGE = 'profiles';

export const LINKROOM_CAPACITY = 15; //15;
export const UNSUPPORTED_BROWSERS = [''];

export const DETAULT_RESELLER_ID =
   'ad1401fcce5df1fc12be071e9efe149505323b20e8cf9493e94150096e4549ad';

export const STAFF_EMAIL_DOMAINS = ['linkroom.com', 'nextlatam.com'];

export const AR_IIFX =
   'https://firebasestorage.googleapis.com/v0/b/linkroom-v2-dev.appspot.com/o/shared%2Far_iifx.jpg?alt=media&token=c3b6e3cb-6420-4083-ac7e-cc38c90c0af5';
export const AR_LINKROOM =
   'https://firebasestorage.googleapis.com/v0/b/linkroom-v2-dev.appspot.com/o/shared%2Far_linkroom.jpg?alt=media&token=1c60cb3f-c5ba-45e0-bbe3-6c957848b08b';
export const AR_OHIO =
   'https://firebasestorage.googleapis.com/v0/b/linkroom-v2-dev.appspot.com/o/shared%2Far_ohio.jpg?alt=media&token=ed021436-9b05-4760-98df-657f9943f783';
