<div id="components-pre-launch">
   <div class="row-pre-launch">
      <div class="column-pre-launch">
         <div class="row-logo-pre-launch">
            <img [src]="logo" alt="Linkroom" />
         </div>
         <div class="row-content-pre-launch">
            <h2>{{ title }}</h2>
            <br />
            <p>
               {{ message }}
            </p>
            <div>
               <button type="button" (click)="redirectToHome()">
                  {{ 'common.go-back' | translate }}
               </button>
               <button type="button" (click)="redirectToHome()" style="display: none;">
                  {{ 'common.go-back' | translate }}
               </button>
            </div>
         </div>
      </div>
   </div>
</div>