<fieldset style="margin: 4vw;">
  CSV:
  <br />
  <input type="file" accept=".csv, .xls, .xlsx" (change)="onFileChange($event)" />

  <br />
  <br />

  Remove {{ records.length }} / {{ total }}

  <br/>
  <br/>
  <br/>
  <button type="button" style="border-radius: 4px; color: white; background-color: red; padding: 5vw;" (click)="deleteAll()">DELETE</button>
</fieldset>
