import { Component, Inject, inject } from '@angular/core';

import * as XLSX from 'xlsx';

import { GalleryService } from '@features/services/gallery.service';

@Component({
  selector: 'app-delete-massively',
  standalone: true,
  imports: [],
  templateUrl: './delete-massively.component.html',
  styleUrl: './delete-massively.component.css'
})
export class DeleteMassivelyComponent {
  status: string = '';
  records: any[] = [];
  total: number = 0;

  constructor(private galleryService: GalleryService) {}

  onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    this.status = 'loading';

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      // Get the first worksheet
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      // Convert to JSON
      const data = <any[]>XLSX.utils.sheet_to_json(ws, { header: 1 });

      // Process the data
      this.processData(data);
    };

    reader.readAsBinaryString(target.files[0]);
  }

  async processData(data: any[]) {
    // Extract headers and rows
    const headers = data[0];
    const rows = data.slice(1);

    // Map rows to your data structure
    this.records = rows.map(row => {
      return {
        id: row[0],
        owner: row[6] == 'everyone' ? undefined : row[6],
        ownerId: row[6] != 'everyone' ? this.getOwnerId(row[5]) : undefined,
        remove: row[8] == 'YES'
      };
    });

    this.total = rows.length;

    this.records = this.records.filter(r => r.remove);
    console.log(`===records====`);
    console.log(this.records);
  }

  getOwnerId(organizationName: string) {
    const organizations = [
      {
        _id: 'GpiUCqaUIixhAaNZpdGu',
        name: 'Buckeye Nation Fan experience',
        roleId: 'fkWktDz7ukVT2J0xftBH',
        roleName: 'Organization Admin'
      },
      {
        _id: '4F5RurIYOy1WiZKmWflG',
        name: 'IIFX Hall of Honors',
        roleId: 'fkWktDz7ukVT2J0xftBH',
        roleName: 'Organization Admin'
      },
      {
        _id: 'vJbADuWYFxRQMpep0KuH',
        name: 'Linkroom',
        roleId: 'fkWktDz7ukVT2J0xftBH',
        roleName: 'Admin'
      },
      {
        _id: 'WILYTQSfYVxrujStqF8H',
        name: 'Stetco',
        roleId: 'fkWktDz7ukVT2J0xftBH',
        roleName: 'Organization Admin'
      }
    ];

    const foundOrganizacion = organizations.find(o => o.name == organizationName);
    return foundOrganizacion ? foundOrganizacion._id : undefined;
  }

  deleteAll() {
    if (this.records.length > 0) {
      const proceed = confirm('sure?');
      if (proceed) {
        this.galleryService.deleteMany(this.records).subscribe(
          r => {
            console.log(`===r====`);
            console.log(r);
          },
          err => {
            console.log(`===err====`);
            console.log(err);
          }
        );
      }
      console.log(`===proceed====`);
      console.log(proceed);
    }
  }
}
